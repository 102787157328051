import $ from 'jquery';

$(() => {
  const programs = $(".program-courses");
  if (programs.length) {
    programs.each((i, programEl) => {
      const program = $(programEl);
      const programTerms = $(program).children();
      programTerms.each((i, termEl) => {
        const term = $(termEl);
        const courses = term.children('.program-course');
        term.children('.program-term-header').on('click', () => {
          if(term.hasClass('open')) {
            courses.removeClass('open');
          }
          term.toggleClass('open');
        });
        courses.each((i, courseEl) => {
          const course = $(courseEl);
          course.children('.program-course-header').on('click', () => {
            course.toggleClass('open');
          });
        })
      })
    });
  }
});