import Glide from '@glidejs/glide';
import $ from 'jquery';

$(() => {
  const carousels = $(".post-carousel");
  if (carousels.length) {
    carousels.each((i, carouselEl) => {
      const carousel = $(carouselEl);
      const getPerView = () => {
        const windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
          return 2;
        }
        if (windowWidth >= 768) {
          return 4;
        }
      };
      const glide = new Glide(carousel.find(".glide")[0], {
        rewind: true,
        perView: getPerView(),
        gap: 16,
        bound: true,
        focusAt: 0
      });
      glide.mount();
      glide.on("resize", () => {
        // console.log(getGap(), getPerView());
        glide.update({ perView: getPerView() });
      });
      glide.on("move.after", () => {
        const index = glide.index + 1;
        carousel.find(".carousel-index").text(index);
      });
    });
  }
});