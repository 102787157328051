import Glide from '@glidejs/glide';
import $ from 'jquery';

$(() => {
  const carousels = $(".program-faculty-carousel");
  if (carousels.length) {
    carousels.each((i, carouselEl) => {
      const carousel = $(carouselEl);
      const getPerView = () => {
        const windowWidth = $(window).outerWidth();
        if (windowWidth < 360) {
          return 1;
        }
        if (windowWidth >= 360) {
          return 3;
        }
      };
      const glide = new Glide(carousel.find(".glide")[0], {
        rewind: true,
        perView: getPerView(),
        gap: 16,
        bound: false
      });
      glide.mount();
      glide.on("resize", () => {
        // console.log(getGap(), getPerView());
        glide.update({ perView: getPerView() });
      });
      glide.on("move.after", () => {
        const index = glide.index + 1;
        carousel.find(".carousel-index").text(index);
      });

      carousel.children().children('.show-more').on('click', () => {
        carousel.children().toggleClass('open');
      })
    });
  }

  const facultyCards = $(".faculty-card");
  if (facultyCards.length) {
    facultyCards.each((i, cardEl) => {
      const card = $(cardEl);
      let cardToggle = card.children('.faculty-headline').children('.faculty-icon');
      cardToggle.on('click', () => {
        card.toggleClass('open');
        if( card.hasClass('open') ) {
          cardToggle.attr('aria-expanded', true);
        } else {
          cardToggle.attr('aria-expanded', false);
        }
      })
    })
  }
});