import tingle from "tingle.js";

const modals = document.querySelectorAll('.js-modal');

// const stopVideo = (modal) => {
//     console.log(modal);
//     var iframe = modal.modalBoxContent.querySelector('iframe');
//     console.log(iframe);
//     var iframeSrc = iframe.src;
//     console.log(iframeSrc);
//     iframe.src = null;
//     console.log(iframe.src);
//     iframe.src = iframeSrc;
//     console.log(iframe.src);
// }

modals.forEach((element) => {
    // instanciate new modal
    const modal = new tingle.modal({
        closeMethods: ['overlay', 'button', 'escape'],
        closeLabel: "Close",
        onClose: function() {
            // maybe stop the video player?
            // stopVideo(modal);
            if(this.modalBoxContent.querySelector('iframe')) {
                // const srcTemp = this.modalBoxContent.querySelector('iframe').src
                this.modalBoxContent.dataset.src = this.modalBoxContent.querySelector('iframe').src;
                this.modalBoxContent.querySelector('iframe').src = '';
                // console.log('closing video modal', this.modalBoxContent.querySelector('iframe'));
            }
            return true; // close the modal
        },
        onOpen: function() {
            // var close = document.querySelector(".tingle-modal__close");
            var close = this.modalCloseBtn;
            var modalContent = this.modalBoxContent;
            if( this.modalBoxContent.dataset && this.modalBoxContent.dataset.src ) {
                this.modalBoxContent.querySelector('iframe').src = this.modalBoxContent.dataset.src;
            }
            modalContent.appendChild(close);
        },
    });

    // modal.onClose = () => {
    //                 // maybe stop the video player?
    //         // stopVideo(modal);
    //         console.log('closing video modal');
    //         if(modal.modalBoxContent.querySelector('iframe')) {
    //             modal.modalBoxContent.querySelector('iframe').src += '';
    //         }
    //         return true; // close the modal
    // }
    // modal.onOpen = () => {
    //     var close = modal.modalCloseBtn;
    //     var modalContent = modal.modalBoxContent;
    //     modalContent.appendChild(close);    
    // }

    const target = document.getElementById(element.dataset.target);
    if(target) {
        modal.setContent(target.innerHTML);
    }
    $(element).on('click', (e) => {
        e.preventDefault();
        modal.open();
    })
});


