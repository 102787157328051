import Glide from "@glidejs/glide";
import $ from "jquery";

$(() => {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      action_load_posts: "load_posts",
    },

    load_carousel: function () {
      const carousels = $(".post-archive-carousel");
      if (carousels.length) {
        carousels.each((i, carouselEl) => {
          const carousel = $(carouselEl);
          const getPerView = () => {
            const windowWidth = $(window).outerWidth();
            if (windowWidth < 768) {
              return 2;
            }
            if (windowWidth >= 768) {
              return 4;
            }
          };
          const glide = new Glide(carousel.find(".glide")[0], {
            rewind: true,
            perView: getPerView(),
            gap: 16,
            bound: true,
            focusAt: 0,
          });
          glide.mount();
          glide.on("resize", () => {
            // console.log(getGap(), getPerView());
            glide.update({ perView: getPerView() });
          });
          glide.on("move.after", () => {
            const index = glide.index + 1;
            carousel.find(".carousel-index").text(index);
          });
        });
      }
    },

    load_posts: function (type, filters, pOffset) {
      Sage.common.loading = true;
      var itemCountReturned;

      if (pOffset === 0) {
        // only clear current contents if the offset is 0 because otherwise we are loading more
        $(".loader").removeClass("all-posts-loaded");
        //AFL.settings.isotopeGrid.isotope('remove',$(".post-block-wrap"));
        $("#posts-wrap").empty();
      }
      $(".loader").addClass("loading");
      var data = {
        action: Sage.common["action_load_" + type],
        paged: pOffset,
      };
      // console.log('filters: ', filters);
      $.get(tfs.ajaxurl, { ...data, ...filters }, function (html) {
        $("#posts-wrap").html(html);
        $(".loader").removeClass("loading");
        Sage.common.loading = false;
        // apply animations right away (if any)
        const first_slide = $("#posts-wrap .glide__slide").eq(1);
        $(".glide__slide")
          .addClass($(first_slide).data("animate") + "-enter")
          .addClass($(first_slide).data("animate") + "-apply");
        Sage.load_carousel();
      }).fail(function (e) {
        console.log(e);
      });
    },
  };

  $(document).on("change", ".j-select-filter", function (e) {
    let id = 0;
    var offset = 0; //$('#posts-wrap > div').length;
    const $this = $(this);
    let data = {};
    e.preventDefault();
    Sage.load_posts("posts", { category: $this.val() }, offset);
  });

  $(document).on("click", ".page-numbers", function (e) {
    e.preventDefault();
    // console.log("click event: ", `${e.target.href}#category-filter`);
    window.location.href = `${e.target.href}#category-filter`;
  });
  // $(document).on("click", ".page-numbers", function (e) {
  //   e.preventDefault();

  //   // divine the data object from the href
  //   const href = $(this).attr("href");
  //   let params = new URLSearchParams(href);
  //   const post_type = "posts";
  //   let data = {};

  //   if (params.get("category")) data["category"] = params.get("category") * 1;
  //   if (params.get("news_produce"))
  //     data["news_produce"] = params.get("news_produce") * 1;

  //   var myRegexp = /page\/([0-9]+)/g;
  //   var match = myRegexp.exec(href);
  //   let offset = 0;
  //   if (match && match.length >= 1) {
  //     // console.log('match: ', match);
  //     offset = match[1];
  //   }
  //   Sage.load_posts(post_type, data, offset);
  // });

  Sage.load_posts("posts");

  //   $('.load-more-btn').click(function(e){
  //   	e.preventDefault();
  //   	var offset = $('#posts-wrap > div').length;
  //   });
});
