import $ from 'jquery';

$(() => {
  MktoForms2.whenReady(function(form){
    form.onSuccess(function(vals,thanksURL){  
      form.getFormElem().each((i, formEl) => {
        const formElem = $(formEl);
        formElem.children('.mktoButtonRow').children('.mktoButtonWrap').children('.mktoButton').attr('role', 'status');
        formElem.children('.mktoButtonRow').children('.mktoButtonWrap').children('.mktoButton').attr('aria-live', 'polite');
        formElem.children('.mktoButtonRow').children('.mktoButtonWrap').children('.mktoButton').html("Thank you!");
      })
      return false;  
    });  
  });
});
