import $ from "jquery";

$(() => {
  const archiveFilter = $(".archive-filter");
  if (archiveFilter.length) {
    archiveFilter.each((i, filterEl) => {
      const filter = $(filterEl);

      filter.on("click", function (e) {
        let linkList = filter.find(".link-list");
        linkList.toggleClass("hidden");
        linkList.toggleClass("flex");
      });
    });
  }
});
