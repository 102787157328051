import $ from 'jquery';

$(() => {
  const stickyNewsletter = $(".sticky-newsletter");
  if (stickyNewsletter.length) {
    stickyNewsletter.each((i, stickyEl) => {
      const sticky = $(stickyEl);
      const newsletterButton = sticky.children('.newsletter-button');
      const background = sticky.children('.newsletter-bg');
      const modal = background.children('.newsletter-modal');
      const closeButton = modal.children('.close-button');

      function openModal() {
        let focusableEls = modal.find("input[type='text'], input[type='email'], button");
        // console.log(focusableEls);
        let firstFocusable = focusableEls.first();
        let lastFocusable = focusableEls.last();
        firstFocusable.trigger('focus');

        lastFocusable.on('keydown', function(e) {
          // console.log(e.key);
          if ((e.key === 'Tab' && !e.shiftKey)) {
            e.preventDefault();
            firstFocusable.trigger('focus');
          }
        })

        firstFocusable.on('keydown', function(e) {
          // console.log(e.key);
          if ((e.key === 'Tab' && e.shiftKey)) {
            e.preventDefault();
            lastFocusable.trigger('focus');
          }
        })
      }

      newsletterButton.on('click', function() {
        sticky.toggleClass('show-modal');
        openModal();
      })

      background.on('click', function(e) {
        e.stopPropagation();
        sticky.removeClass('show-modal');
      })

      modal.on('keyup', function(e) {
        if (e.key == "Escape") {
          sticky.removeClass('show-modal');
          newsletterButton.trigger('focus');
        }
      })

      modal.on('click', function(e) {
        e.stopPropagation();
      })
      
      closeButton.on('click', function() {
        // console.log('closing newsletter modal');
        sticky.removeClass('show-modal');
      })
    });
  }
});